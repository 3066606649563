import 'element-ui/lib/theme-chalk/index.css';
import lang from 'element-ui/lib/locale/lang/en';
import locale from 'element-ui/lib/locale';

import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';

import App from './App.vue';
import router from './router';

Vue.config.productionTip = false;
// Vue.use(ElementUI, { lang });

locale.use(lang);

if (process.env.NODE_ENV == 'production') {
  Sentry.init({
    Vue,
    dsn: "https://09c0e30ed8f249dbb7e6ff2b8800d5db@o280113.ingest.sentry.io/5646211",
    integrations: [new Integrations.BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');