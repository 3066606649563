<template>
  <div class="main-content">

    <div style="text-align: center;">
      <h1>MaskRadar</h1>

      <p>
        Powered by <a href="https://www.thehashmasks.com/" target="_blank">Hashmasks</a>.
        Built by <a href="https://twitter.com/yobaguy" target="_blank">YobaGuy</a>
      </p>
      <p style="margin-top: 40px;">
        <a class="twitter-share-button" target="_blank"
          :href="`https://twitter.com/intent/tweet?hashtags=Hashmasks&related=thehashmasks&text=Twitter%20Publish&url=${getFullUrl}&via=yobaguy`"
        >

        <!-- https://publish.twitter.com/?buttonHashtag=%23Hashmasks&buttonLarge=on&buttonRecommendation=yobaguy&buttonType=TweetButton&buttonUrl=https%3A%2F%2Fwww.google.com&buttonVia=qqqyobaguyqqq&widget=Button -->

          <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="twitter" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-twitter fa-w-16 fa-3x"><path fill="currentColor" d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z" class=""></path></svg>

          Share your search
        </a>
        <!-- Share -->
      </p>
    </div>

    <!-- <div class="filter__search">
      <el-input
        placeholder="@username"
        v-model="input"
        v-on:keyup.enter.native="submitData"
        :autofocus="true">
          <el-button v-on:click="submitData" slot="append" icon="el-icon-search"></el-button>
      </el-input>
    </div> -->

    <br>
    <br>
    <br>

    <div class="filters-wrapper">
      <div>
        <h4>Sort by</h4>

        <el-select v-model="sort.currentValue" class="filter" @change="changeSort">
            <el-option v-for="option in sort.options" :key="option.key" :label="option.label" :value="option.key">
            </el-option>
        </el-select>
      </div>

      <div class="filter__search">
        <h4>Search</h4>

        <el-input
          placeholder="Token id"
          v-model="searchInput"
          v-on:keyup.enter.native="updateSearchWord"
        >
            <el-button v-on:click="updateSearchWord" slot="append" icon="el-icon-search"></el-button>
        </el-input>
      </div>

    </div> <!-- filters-wrapper -->

    <div class="filters-wrapper">
      <div v-for="filter in filters" :key="filter.key">
        <h4>{{ filter.title }}</h4>

        <el-select
          v-model="filtersCurrentValues[filter.key]"
          placeholder="Any"
          clearable filterable
          class="filter"
          @change="changeFilter"
          :class="{'filter--has-value': filtersCurrentValues[filter.key] != ''}"
        >
            <el-option
              v-for="option in filter.options"
              :key="option"
              :label="option"
              :value="option"
            >
              <span>{{option}}</span>
              <span class="filter__stats">{{getTextForStats(filter.key, option)}}</span>
            </el-option>
        </el-select>
      </div>
    </div> <!-- filters-wrapper -->

    <div class="filters-wrapper">

      <div>
        <h4>Background</h4>
        <el-select
          v-model="filtersCurrentValues.background_id"
          placeholder="Any"
          clearable
          class="filter"
          popper-class="filter--background"
          @change="changeFilter"
          :class="{'filter--has-value': filtersCurrentValues.background_id != ''}"
        >
            <el-option
              v-for="option in customFilters.background_id.options"
              :key="option"
              :label="option"
              :value="option"
            >
              <h2>{{option}} <span class="filter__stats">{{getTextForStats('background_id', option)}}</span></h2>
              

              <img
                v-for="imageId in backgroundExamples[option]"
                :key="imageId"
                :src="`https://hashmasksstore.blob.core.windows.net/hashmasksthumbs/${imageId}.png`"
              >
            </el-option>
        </el-select>
      </div>

      <div>
        <h4>Background style</h4>

        <el-select
          v-model="filtersCurrentValues.background_style"
          placeholder="Any"
          clearable
          class="filter"
          @change="changeFilter"
          :class="{'filter--has-value': filtersCurrentValues.background_style != ''}"
        >
            <el-option
              v-for="option in customFilters.background_style.options"
              :key="option"
              :label="option"
              :value="option"
            >
              <span>{{ option }}</span>
              <span class="filter__stats">{{getTextForStats('background_style', option)}}</span>
            </el-option>
        </el-select>
      </div>

      <div>
        <h4>Background main color</h4>

        <el-select
          style="width: 230px;"
          v-model="filtersCurrentValues.background_main_color"
          placeholder="Any"
          clearable
          class="filter filter--custom-long"
          popper-class="filter__content"
          @change="changeFilter"
          :class="{'filter--has-value': filtersCurrentValues.background_main_color != ''}"
        >
            <el-option
              v-for="option in customFilters.background_main_color.options"
              :key="option"
              :label="option.replace('_', ' ')"
              :value="option"
            >
              <div class="color-box" :style="{background: getColorBox(option) }"></div>
              <span>{{ option.replace('_', ' ') }}</span>
              <span class="filter__stats">{{getTextForStats('background_main_color', option)}}</span>
            </el-option>
        </el-select>
      </div>

      <div>
        <h4>Background colors</h4>
        <el-select
          multiple
          style="width: 300px;"
          v-model="filtersCurrentValues.background_colors"
          placeholder="Any"
          clearable
          class="filter filter--custom-long"
          popper-class="filter__content"
          @change="changeFilter"
        >
            <el-option
              v-for="option in customFilters.background_colors.options"
              :key="option"
              :label="option.replace('_', ' ')"
              :value="option"
            >
              <div class="color-box" :style="{background: getColorBox(option) }"></div>
              <span>{{ option.replace('_', ' ') }}</span>
              <span class="filter__stats">{{getTextForStats('background_colors', option)}}</span>
            </el-option>
        </el-select>
      </div>

    </div> <!-- filters-wrapper -->


    <div class="filters-wrapper">
      <div>
        <h4>Mask Specific</h4>

        <!-- disabled -->
        <el-select
          v-model="filtersCurrentValues.mask_id"
          placeholder="Any"
          clearable filterable
          class="filter"
          @change="changeFilter"
          popper-class="filter--mask-specific"
          :class="{'filter--has-value': filtersCurrentValues.mask_id != ''}"
        >
            <el-option
              v-for="(empty, maskPos) in customFilters.mask_id.options"
              :key="`mask_id=${getMaskIdByPositionInArray(maskPos)}`"
              :label="getMaskTitle(maskPos)"
              :value="getMaskIdByPositionInArray(maskPos)"
            >

              <img :src="`/images/mask-thumbs/${getMaskIdByPositionInArray(maskPos)}.png`">

              <div>
                <h2>{{getMaskTitle(maskPos)}}</h2>
                <p class="filter__stats">{{getTextForStats('mask_id', maskPos)}}</p>
              </div>

              <!-- maskPos #{{maskPos}}; -->
              <!-- Mask #{{getMaskIdByPositionInArray(maskPos)}}; -->
            </el-option>
        </el-select>
      </div>

      <div>
        <h4>Mask main color</h4>

        <el-select
          style="width: 230px;"
          v-model="filtersCurrentValues.mask_main_color"
          placeholder="Any"
          clearable
          class="filter filter--custom-long"
          popper-class="filter__content"
          @change="changeFilter"
          :class="{'filter--has-value': filtersCurrentValues.mask_main_color != ''}"
        >
            <el-option
              v-for="option in customFilters.mask_main_color.options"
              :key="option"
              :label="option.replace('_', ' ')"
              :value="option"
            >
              <div class="color-box" :style="{background: getColorBox(option) }"></div>
              <span>{{ option.replace('_', ' ') }}</span>
              <span class="filter__stats">{{getTextForStats('mask_main_color', option)}}</span>
            </el-option>
        </el-select>
      </div>

      <div>
        <h4>Mask colors</h4>
        <el-select
          multiple
          style="width: 300px;"
          v-model="filtersCurrentValues.mask_colors"
          placeholder="Any"
          clearable
          class="filter filter--custom-long"
          popper-class="filter__content"
          @change="changeFilter"
        >
            <el-option
              v-for="option in customFilters.mask_colors.options"
              :key="option"
              :label="option.replace('_', ' ')"
              :value="option"
            >
              <div class="color-box" :style="{background: getColorBox(option) }"></div>
              <span>{{ option.replace('_', ' ') }}</span>
              <span class="filter__stats">{{getTextForStats('mask_colors', option)}}</span>
            </el-option>
        </el-select>
      </div>
    </div> <!-- filters-wrapper -->

    <div class="filters-wrapper">
      <div v-for="filter in futureFilters.hair" :key="filter">
        <h4>{{filter}} (coming soon)</h4>
        <el-select disabled placeholder="Any" class="filter" value="">>
          <el-option label="lorem" value="lorem"></el-option>
        </el-select>
      </div>
    </div> <!-- filters-wrapper -->

    <div class="found-items-stats">
      Showing {{itemsPerPage * (currentPage-1)}} - {{getLastItemOnPage}} of {{getData.length}} results
    </div>

    <div class="mask-list">
      <el-card class="mask-item"
        v-for="item in getData.slice(itemsPerPage * (currentPage-1), itemsPerPage * currentPage)"
        :key="item.token_id"
        :class="{'debug-mask-item': isDebugMod}"
      >
        <a :href="`https://hashmasksstore.blob.core.windows.net/hashmasks/${item.image_id}.jpg`" target="_blank">
          <img :src="`https://hashmasksstore.blob.core.windows.net/hashmasksthumbs/${item.image_id}.png`">
        </a>

        <div class="mask-item__content">
          <h2>
            <span>{{ item.name ? item.name : 'Unnamed' }}</span>
            <a class="opensea-link" target="_blank" 
              :href="`https://opensea.io/assets/0xc2c747e0f7004f9e8817db2ca4997657a7746928/${item.token_id}`"
            >
              <img src="/opensea-logo.png">
            </a>
          </h2>
          <h3>
            #{{item.token_id}}
          </h3>
          <!-- <a href="https://twitter.com/boredhash/status/1403725339742904326" target="_blank" class="hashgalleries-link">Giveaway</a> -->
          <!-- <a href="https://galleries.boredhash.com" target="_blank" class="hashgalleries-link">Bored Hash Galleries</a> -->
          <!-- <h4>#{{item.token_id}}</h4> -->
          <div v-if="isDebugMod">
            <p style="margin-left: 20px;">{{item.background_hash}}</p>
            <p style="margin-left: 20px;">image_id: {{item.image_id}}</p>
          </div>

          <el-collapse v-model="activeCollapses" v-if="!isDebugMod">

            <el-collapse-item title="Basic properties" :name="`${item.token_id}-basic`">
              <!-- Consistency<i class="header-icon el-icon-info"></i> -->
              <!-- <template slot="title">
                <h3>Basic properties</h3>
              </template> -->

              <div class="mask-item__content__properties">
                <!-- <div>
                  <p>id</p> {{item.token_id}}
                </div> -->
                <div>
                  <p>Image id</p>
                  <p>{{item.image_id}}</p>
                </div>
                <div>
                  <p>Character</p>
                  <p>{{ item.character }}</p>
                  <p>{{ getTextForStats('character', item.character) }}</p>
                </div>
                <div>
                  <p>Mask</p>
                  <p>{{item.mask}}</p>
                  <p>{{ getTextForStats('mask', item.mask) }}</p>
                </div>
                <div>
                  <p>Eye color</p>
                  <p>{{item.eye_color}}</p>
                  <p>{{ getTextForStats('eye_color', item.eye_color) }}</p>
                </div>
                <div>
                  <p>Skin color</p>
                  <p>{{item.skin_color}}</p>
                  <p>{{ getTextForStats('skin_color', item.skin_color) }}</p>
                </div>
                <div>
                  <p>Item</p>
                  <p>{{item.item}}</p>
                  <p>{{ getTextForStats('item', item.item) }}</p>
                </div>
              </div>
            </el-collapse-item>

            <el-collapse-item title="Background properties" :name="`${item.token_id}-background`">
              <!-- <template slot="title">
                <h3>Background properties</h3>
              </template> -->
              <div class="mask-item__content__properties mask-item__content__properties--background">
                <div class="full-width">
                  <p>Hash</p>
                  <p>{{item.background_hash}}</p>
                  <!-- <p>{{ getTextForStats('background_hash', item.background_hash) }}</p> -->
                </div>
                <div>
                  <p>Title</p>
                  <p>{{item.background_id}}</p>
                  <p>{{ getTextForStats('background_id', item.background_id) }}</p>
                </div>
                <div>
                  <p>Style</p>
                  <p>{{item.background_style}}</p>
                  <p>{{ getTextForStats('background_style', item.background_style) }}</p>
                </div>
                <div>
                  <p>Main color</p>
                  <div>
                    <div class="color-box" :style="{background: getColorBox(item.background_main_color)}"></div>
                    <span>{{ item.background_main_color.replace('_', ' ') }}</span>
                    <p>{{ getTextForStats('background_main_color', item.background_main_color) }}</p>
                  </div>
                </div>
                
                <div>
                  <p>Colors</p>
                  <div>
                    <span v-for="color in item.background_colors.split(',')" :key="color" class="properties__colors">
                      <span class="color-box" :style="{background: getColorBox(color)}"></span>
                      <span>{{ color.replace('_', ' ') }}</span>
                    </span>
                  </div>
                </div>

              </div>
            </el-collapse-item>


            <el-collapse-item disabled title="Mask properties (coming soon)" :name="`${item.token_id}-mask`">
              <div class="mask-item__content__properties">
                <div><span>id:</span> 0 <!-- {{item.mask_id}} --></div>
                <div><span>Subcategory:</span> Fox <!-- {{item.mask_subcategory}} --></div>
                <div><span>Attributes:</span> heart <!-- {{item.mask_attributes}} --></div>
                <div><span>Main color:</span> red <!-- {{item.mask_main_color}} --></div>
                <div><span>Colors:</span> red, blue, white <!-- {{item.mask_colors}} --></div>
              </div>
            </el-collapse-item>

            <el-collapse-item disabled title="Hair properties (coming soon)" :name="`${item.token_id}-hair`">
              <!-- hair_id hair_hash hair_color  hair_length easter_egg -->
            </el-collapse-item>

          </el-collapse>


        </div>
      </el-card>
    </div> <!-- mask-list -->

    <el-pagination
      :page-size="itemsPerPage"
      :pager-count="11"
      :hide-on-single-page="false"
      background
      layout="prev, pager, next"
      :total="getData.length"
      @current-change="changePage"
      :current-page.sync="currentPage"
    >
      <!-- @current-change="(x,) =>  currentPage = newPage" -->
    </el-pagination>

  </div>
</template>

<script>

import Vue from 'vue';
import { Input, Button, Card, Loading, Select, Option, Collapse, CollapseItem, Pagination } from 'element-ui';

import masksData from '@/assets/final_data.json';
import statsData from '@/assets/stats.json';
import masksTitles from '@/assets/masks_titles.json';

Vue.use(Loading.directive);

/*
// Hidden treats:
// 14300 есть пасхалка в виде банана?
// 5427, 5295, 403, 450 есть человечек (на 450 еще и феникс есть)
// 8892 точка справа посередине
// 6322, какие-то санни (куб?) сверху, между волнами чуть ниже
// 998 - ноты?
// 
*/


// пасхалка в виде губ? https://twitter.com/Cap_Plantain/status/1361419165068759041/photo/1
// writed 16383 to 301; most popular masks: 85 has 225; 100 has 191; 195 has 187; 34 has 180;


export default {
  name: 'Homepage',
  components: {
    'el-input': Input,
    'el-button': Button,
    'el-card': Card,
    'el-select': Select,
    'el-option': Option,
    'el-collapse': Collapse,
    'el-collapse-item': CollapseItem,
    'el-pagination': Pagination,
  },
  data() {
    return {
      isDebugMod: false,
      currentPage: 1,
      itemsPerPage: 52,

      futureFilters: {
        hair: [
          'Hair', // hair_id
          'Hair color', // hair_color 
          'Hair length', // hair_length
        ]
      },

      // this is image_id
      backgroundExamples: {
        "Geometry": ["10141", "10144", "10175", "10173"],
        "Invasion": [ "10142", "10152", "10162", "10419"],
        "Rug": ["10143","10201","10210","10232"],
        "Tribe": ["10145","10167","10171","10200"],
        "Night City": ["10146","10148","10161","10157"],
        "New World": ["10150","10151","10182","10184"],
        "Jurassic": ["10153","10159","10240","10180"],
        "Desert": ["10155","10223","10205","10191"],
        "Text": ["10156","10188","10254","10309"],
        "New York": ["10243","10186","10199","10202"],
        "Pixels": ["10192","10194","10207","10221"],
        "The End": ["10406", "10544", "10480", "11248"],

        // "undefined": [
        //   "10139",
        //   "10140"
        // ]
      },

      colorBoxes: {
        dark_cyan: 'darkcyan',
        lemon: 'palegreen',
        depressive_blue: 'darkblue',
        light_purple: 'thistle',
        dark_blue: 'darkblue',
        dark_green: 'darkgreen',
        dark_purple: 'purple',
        multiple: 'radial-gradient(red, yellow, blue)',
      },

      activeCollapses: [],

      searchInput: '',
      searchWord: '',
      sort: {
        options: [
          {
            key: 'token_id',
            label: 'Token id',
          },
          {
            key: 'image_id',
            label: 'Image id',
          },
          // {
          //   key: 'name',
          //   label: 'Name',
          // },
        ],
        currentValue: 'token_id'
      },

      customFilters: {
        background_id: {
          title: 'Background',
          key: 'background_id',
          options: Object.keys(statsData.background_id)
        },
        background_main_color: {
          title: 'Background main color',
          key: 'background_main_color',
          options: Object.keys(statsData.background_main_color)
        },
        background_colors: {
          title: 'Background colors',
          key: 'background_colors',
          options: Object.keys(statsData.background_colors)
        },
        background_style: {
          title: 'Background style',
          key: 'background_style',
          options: Object.keys(statsData.background_style),
        },


        mask_id: {
          title: 'Mask specific',
          key: 'mask_id',
          options: new Array(statsData.mask_id.length),
          // options: Object.keys(statsData.mask_id),
        },
        mask_main_color: {
          title: 'Mask main color',
          key: 'mask_main_color',
          options: Object.keys(statsData.mask_main_color),
        },
        mask_colors: {
          title: 'Mask colors',
          key: 'mask_colors',
          options: Object.keys(statsData.mask_colors),
        },
      },

      filtersCurrentValues: {
        // base filters:
        character: '',
        mask: '',
        eye_color: '',
        skin_color: '',
        item: '',

        // custom filters:
        background_id: '',
        background_main_color: '',
        background_colors: [],
        background_style: '',

        mask_id: '',
        mask_colors: [],
        mask_main_color: '',
      },
      filters: [
        {
          title: 'Character',
          key: 'character',
          // options: ['Male', 'Female']
          options: Object.keys(statsData.character)
        },
        {
          title: 'Mask',
          key: 'mask',
          // options: ['Indian', 'Chinese', 'Animal']
          options: Object.keys(statsData.mask)
        },
        {
          title: 'Eye color',
          key: 'eye_color',
          // options: ['Freak', 'Dark', 'Green']
          options: Object.keys(statsData.eye_color)
        },
        {
          title: 'Skin',
          key: 'skin_color',
          // options: ['Freak', 'Dark', 'Light']
          options: Object.keys(statsData.skin_color)
        },
        {
          title: 'Item',
          key: 'item',
          // options: ['Mirror', 'No Item', 'Golden Toilet Paper']
          options: Object.keys(statsData.item)
        },

        // mask_id mask_subcategory  mask_main_color mask_colors mask_attributes background_id background_hash background_main_color background_colors background_style  hair_id hair_hash hair_color  hair_length easter_egg
      ], // filters

      isLoading: false,

      backgroundHashes: [],

      // massFollowersFilter: '',
      // massFollowersOptions: [
      //   {
      //     value: 'show',
      //     label: 'Show only mass-followers',
      //   },
      // ],
    };
  }, // data
  mounted() {
    this.loadFiltersFromUrl();

    // for (const mask of masksData) {
    //   if (this.backgroundHashes.findIndex(x => x === mask.background_hash) === -1) {
    //     this.backgroundHashes.push(mask.background_hash);
    //   }
    // }

    // for (const mask of this.newBackgroundHashes) {
    //   for (const option of mask.options) {
    //     this.backgroundHashes = this.backgroundHashes.concat(option.external_hashes);
    //   }
    // }
  },
  // watch: {
    // filtersCurrentValues: {
    //   handler(newFilters, oldFilters) {
    //     this.updateUrl(newFilters);
    //   },
    //   deep: true
    // },

    // lastName: function (val) {
    //   this.fullName = this.firstName + ' ' + val
    // }
  // },
  methods: {
    getMaskIdByPositionInArray(pos) {
      return statsData.mask_id[pos].id;
    },
    getMaskTitle(pos) {
      const maskId = this.getMaskIdByPositionInArray(pos);
      let title = masksTitles[maskId].mask_title;
      if (!title) title = 'Mask #' + pos;
      return title;
    },

    loadFiltersFromUrl() {
      const queryFilters = this.$route.query;

      for (const filterKey in queryFilters) {
        const filterValue = queryFilters[filterKey];

        // console.log(filterKey, filterValue);
        // if (typeof filterValue === 'string' && filterValue) {
        if (filterValue) {
          if (filterKey === 'page') {
            this.currentPage = Number(filterValue);
          }
          else if (filterKey === 'token_id') {
            this.searchWord = filterValue;
            this.searchInput = filterValue;
          }
          else if (filterKey === 'sort_by') {
            this.sort.currentValue = filterValue;
          }
          // если указан только один цвет, то filterValue будет обычной строкой
          // если больше одного, то будет [] и можно напрямую взять значение
          else if (filterKey === 'background_colors' && typeof filterValue === 'string') {
            this.filtersCurrentValues['background_colors'] = [filterValue];
          }
          else if (filterKey === 'mask_colors' && typeof filterValue === 'string') {
            this.filtersCurrentValues['mask_colors'] = [filterValue];
          }
          else {
            this.filtersCurrentValues[filterKey] = filterValue;
          }
        }
      }
    },

    updateUrl() {

      const filters = this.filtersCurrentValues;
      const constFiltersWithoutEmptyValues = Object.keys(filters)
                                              .filter((k) => filters[k] != '')
                                              .reduce((a, k) => ({ ...a, [k]: filters[k] }), {});

      if (this.currentPage > 1) constFiltersWithoutEmptyValues.page = this.currentPage;
      if (this.searchWord) constFiltersWithoutEmptyValues.token_id = this.searchWord;
      if (this.sort.currentValue !== 'token_id') constFiltersWithoutEmptyValues.sort_by = this.sort.currentValue;

      this.$router.push({ path: '/', query: constFiltersWithoutEmptyValues});

      // for (const filterKey in newFilters) {
      //   const filterValue = newFilters[filterKey];
      //   // console.log(filterKey, filterValue);
      //   // if (typeof filterValue === 'string' && filterValue) {
      //   if (filterValue) {
          
      //     const newQuery = {...this.$route.query};
      //     newQuery[filterKey] = filterValue;

      //     // const newQuery = {background_id: "Night City"};

      //     console.log('newQuery', newQuery)
      //     this.$router.push({ path: '/', query: newQuery});
      //   }
      // }
      // this.$router.replace({ query: { q1: "q1" } })
    }, // updateUrl

    updateSearchWord() {
      this.searchWord = this.searchInput;
      this.updateUrl();
    },

    changeSort() {
      this.updateUrl();
    },

    changeFilter() {
      this.currentPage = 1;
      this.updateUrl();
    },

    changePage(newPage) {
      this.currentPage = newPage;
      this.updateUrl();
      // window.scrollTo(0, 0);
    },

    getColorBox(color) {
      return this.colorBoxes[color] ? this.colorBoxes[color] : color;
    },

    getPercentage(number) {
      return (Number(number) / 16384 * 100).toFixed(2);
    },

    getTextForStats(property, propertyValue) {
      let value = value = statsData[property][propertyValue];
      if (property === 'mask_id') value = value.stats;

      return `(${value} / ${this.getPercentage(value)}%)`;
    },

    changeBasicFilter() {

      // if (!this.tableData.length) {
      //   return;
      // }

      // this.submitData();
    },

  }, // methods
  computed: {
    // getStatsData() {
    //   return statsData;
    // },

    getFullUrl() {
      return 'https://maskradar.yobalabs.com' + this.$route.fullPath;
    },

    getLastItemOnPage() {
      // -1 потому что у .slice() второй параметр excluded
      const lastPossibleItem = this.itemsPerPage * this.currentPage - 1;
      return this.getData.length < lastPossibleItem ? this.getData.length : lastPossibleItem;
    },

    getData() {
      const result = [];

      // for (const hash of this.backgroundHashes) {
      //   const mask = masksData.find(x => x.background_hash === hash);
      //   result.push(mask);
      // }

      // return result;


      // return masksData.sort((a, b) => Number(a.image_id) - Number(b.image_id)).slice(0, 100);

      const data = masksData.filter((mask) => {
        // let isSuitable = true;

        for (const key in this.filtersCurrentValues) {
          const currentValue = this.filtersCurrentValues[key];

          if (key === 'background_colors' || key === 'mask_colors') {
            if (currentValue.length == 0) continue;
            if (!mask[key]) return false;

            let checker = (arr, target) => target.every(v => arr.includes(v));
            const maskHasAllFilteredColors = checker(mask[key].split(','), currentValue);
            if (maskHasAllFilteredColors) continue;
            else return false;
          }

          if (currentValue && currentValue != mask[key]) {
            return false;
          }

        }

        if (this.searchWord) {
          // if (mask.token_id != this.searchWord || (!mask.name.includes(this.searchWord))) {
          // if ((mask.name && !mask.name.includes(this.searchWord))) {
          if (mask.token_id != this.searchWord) {
            return false;
          }
        }

        return true;
        // return isSuitable;
      });
      // }).slice(0, 100);

      if (this.sort.currentValue === 'image_id') {
        return data.sort((a, b) => a.image_id - b.image_id);
      }
      // else if (this.sort.currentValue === 'name') {
      //   return data.sort((a, b) => {
      //     if (!a) a = '';
      //     if (!b) b = '';
      //     return a.name.localeCompare(b.name);
      //   });
      // }

      return data;

    },
  }, // computed
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

  a {
    color: #039be5;
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
  }

  .twitter-share-button {
    background-color: #1b95e0;
    height: 20px;
    box-sizing: border-box;
    padding: 1px 8px 1px 6px;
    color: #fff;
    border-radius: 3px;
    font-weight: 500;
    cursor: pointer;

    margin-top: 20px;
    padding: 10px 20px;

    &:hover {
      background-color: #0c7abf;
    }

    svg {
      max-width: 15px;
      margin-right: 5px;
      color: #fff;
      vertical-align: middle;
    }
  }

  .filter__search .el-input{
    width: 300px;
    // margin-top: 50px;
  }

  .filter__search .el-input div.el-input-group__append{
    transition: 0.1s;
  }
  .filter__search .el-input div.el-input-group__append:hover{
    background: rgba(227,234,255,.7);
  }

  .el-pagination {
    margin-top: 60px;
    text-align: center;

    li {
      background: transparent;
      font-size: 17px;
      min-width: 40px;
    }
  }

  .main-content{
    max-width: 1250px;
    margin: 60px auto;
  }

  .filters-wrapper{
    // margin-bottom: 5px;
    display: flex;

    h4 {
      margin-bottom: 4px;
      font-weight: normal;
      font-size: 12px;

      // color: #9e9e9e; rgba(158 158 158);
      color: rgb(108, 108, 108);
    }
  }

  .filters-wrapper .filter:not(.filter--custom-long).el-select .el-input__inner{
    width: 190px;
  }

  .filters-wrapper .filter--custom-long.el-select .el-input__inner{
    width: 100%;
  }

  .filter {
    margin-right: 20px;

    .el-input__inner {
      text-transform: capitalize;
    }
  }

  .filter--has-value .el-input__suffix .el-icon-arrow-up:before {
    content: "\e78d";
  }

  .filter__content {
    .el-select-dropdown__item span {
      text-transform: capitalize;
      color: #000;
    }
  }

  .filter__stats {
    color: rgb(70, 70, 70);
    margin-left: 5px;
    font-size: 11px;
    vertical-align: middle;
  }

  .filter--background,
  .filter--mask-specific {
    .el-scrollbar__wrap {
      min-height: 500px;
    }

    .el-select-dropdown__list {
      display: flex;
      flex-wrap: wrap;
      max-width: 560px;
    }

    .el-select-dropdown__item {
      width: 280px;
      height: auto;

      h2 {
        margin-bottom: 0;
        margin-top: 5px;
        font-weight: normal;
        color: rgba(0, 0, 0, 0.9);
      }

      img {
        max-width: 50px;
        margin-right: 10px;
      }

      p {
        margin: 0;
      }
    }
  }

  .filter--background {
    
  } // filter--background

  .filter--mask-specific {
    .el-select-dropdown__list {
      max-width: 500px;
    }

    .el-select-dropdown__item {
      margin-top: 20px;
      width: 250px;
      display: flex;

      & > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      h2 {
        font-size: 16px;
        margin: 0;
        margin-bottom: 10px;
      }

      h2,
      p {
        line-height: 100%;
      }

      img {
        max-height: 50px;
      }
    }
  }

  .filter__content .color-box {
    margin-right: 7px;
  }

  .found-items-stats {
    margin: 30px 0;
  }

  .color-box {
    margin-right: 5px;
    width: 10px;
    height: 10px;
    display: inline-block;
    border: 1px solid #aaa;
    vertical-align: middle;
  }

  .mask-list {
    display: flex;
    // justify-content: space-between;
    margin-left: -10px;
    flex-wrap: wrap;
  }

  .debug-mask-item {
    width: 180px !important;
  }

  .mask-item {
    // width: 380px;
    width: 290px;
    margin: 0 10px 20px;

    .el-card__body {
      padding: 0;
    }

    img {
      width: 100%;
      display: block;
    }

    p {
      margin: 10px 0;
    }
  }

  .mask-item__content {
    // padding: 20px;
    padding-top: 0;

    h2,
    h3,
    .hashgalleries-link,
    .el-collapse-item__header {
      padding: 0 20px;
    }

    .hashgalleries-link {
      margin-bottom: 15px;
      display: inline-block;

      &:hover {
        text-decoration: underline;
      }
    }

    h2 {
      margin: 15px 0 5px;
      display: flex;
      justify-content: space-between;

      span {
        max-width: 90%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 21px;
      }
    }

    h3 {
      margin: 0 0 15px;
      font-weight: normal;
      color: rgb(70, 70, 70);
    }

    .opensea-link {
      display: flex;
      justify-content: center;
      flex-direction: column;

      img {
        max-width: 20px;
      }
    }

    .el-collapse-item__header {
      font-size: 16px;
    }

    .el-collapse-item__content {
      padding-bottom: 0;
    }
  }

  .mask-item__content__properties {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;


    // background: #fbfcfd;
    background: #f2f7fa;
    background: linear-gradient(to bottom, #fbfcfd, #f2f7fa);
    // margin: 0 15px;
    // border-radius: 15px;
    padding: 20px 15px;

    .properties__colors {
      margin-right: 7px;
      display: inline-block;
    }

    &.mask-item__content__properties--background > div {
      width: 48%;
    }

    & > div {
      font-size: 12px;
      // margin: 0 20px;
      margin-bottom: 15px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      width: 120px;
      box-sizing: border-box;
      text-align: center;
      // padding: 10px;
      padding: 8px 0;

      box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
      border-radius: 8px;
      background: #fff;
      // background: rgba(0, 0, 0, 0.05);

      &.full-width {
        width: 100%;
      }

      &:nth-child(7) {
        width: 100%;
      }

      // &:nth-child(5),
      // &:nth-child(6) {
      //   width: 50%;
      // }

      // &:nth-child(3n+1):nth-last-child(1),
      // &:nth-child(3n):nth-last-child(2) {

      // &:nth-child(4):nth-last-child(-n+2),
      // &:nth-child(5):nth-last-child(-n+1) {
      //   width: 45%;
      // }
    }

    p {
      margin: 0;
    }
    
    p:nth-child(1) {
      font-size: 12px;
      color: #6c6c6c;
    }

    p:nth-child(3) {
      color: rgb(70, 70, 70);
    }

    p:nth-child(1):nth-last-of-type(-n+1),
    p:nth-child(2) {
      font-weight: bold;
      font-size: 14px;
    }
  }

  

</style>
